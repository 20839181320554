{
  "name": "atomickit-web",
  "version": "1.27.1",
  "description": "Atomickit web repo",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build:prod": "ng build --configuration production && npm run compress",
    "build:dev": "ng build --configuration dev && npm run compress",
    "compress": "gzipper c ./dist",
    "test": "ng test",
    "test:coverage": "NODE_OPTIONS=\"--max-old-space-size=4096\" ng test --no-watch --code-coverage --source-map=true",
    "lint": "ng lint",
    "sentry:inject": "sentry-cli sourcemaps inject --org atomickit --project atomickit-web ./dist",
    "sentry:upload": "sentry-cli sourcemaps upload --org atomickit --project atomickit-web ./dist --release v\"$(echo $npm_package_version)\"",
    "sentry:sourcemaps": "npm run sentry:inject && npm run sentry:upload"
  },
  "private": true,
  "engines": {
    "node": "^20",
    "npm": "^10"
  },
  "dependencies": {
    "@angular/animations": "^17.3.4",
    "@angular/cdk": "^17.3.4",
    "@angular/common": "^17.3.4",
    "@angular/compiler": "^17.3.4",
    "@angular/core": "^17.3.4",
    "@angular/forms": "^17.3.4",
    "@angular/localize": "^17.3.4",
    "@angular/material": "^17.3.4",
    "@angular/platform-browser": "^17.3.4",
    "@angular/platform-browser-dynamic": "^17.3.4",
    "@angular/router": "^17.3.4",
    "@atomickit/atomickit-constants": "^0.4.9",
    "@atomickit/ngx-datatable": "21.0.0",
    "@aws-sdk/client-s3": "^3.312.0",
    "@aws-sdk/credential-providers": "^3.312.0",
    "@ng-bootstrap/ng-bootstrap": "16.0.0",
    "@ng-select/ng-select": "^12.0.4",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.6",
    "@sentry/angular-ivy": "7.84.0",
    "angular-code-input": "^2.0.0",
    "angular-web-storage": "^16.0.0",
    "aws-amplify": "^6.0.20",
    "bootstrap": "5.3.2",
    "gzipper": "^7.2.0",
    "jquery": "3.6.4",
    "jwt-decode": "^3.1.2",
    "lodash": "^4.17.21",
    "mixpanel-browser": "^2.48.1",
    "moment": "^2.29.4",
    "ngx-csv-parser": "1.3.1",
    "ngx-dropzone": "^3.1.0",
    "ngx-perfect-scrollbar": "^10.1.1",
    "ngx-scrollbar": "^13.0.3",
    "ngx-skeleton-loader": "^8.1.0",
    "ngx-toastr": "^18.0.0",
    "rxjs": "^7.8.1",
    "stream": "^0.0.2",
    "survey-angular-ui": "^1.9.116",
    "survey-core": "^1.9.116",
    "ts-mixer": "^6.0.3",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.4",
    "@angular-eslint/builder": "17.3.0",
    "@angular-eslint/eslint-plugin": "17.3.0",
    "@angular-eslint/eslint-plugin-template": "17.3.0",
    "@angular-eslint/schematics": "17.3.0",
    "@angular-eslint/template-parser": "17.3.0",
    "@angular/cli": "^17.3.4",
    "@angular/compiler-cli": "^17.3.4",
    "@ngrx/store-devtools": "^17.2.0",
    "@sentry/cli": "2.22.3",
    "@types/jasmine": "^5.1.4",
    "@types/jquery": "^3.5.9",
    "@types/lodash": "^4.14.182",
    "@types/node": "^20.9.0",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "aws-sdk-client-mock": "^1.0.0",
    "aws-sdk-mock": "^5.7.0",
    "codelyzer": "^6.0.2",
    "eslint": "^8.53.0",
    "eslint-config-airbnb-base": "^15.0.0",
    "eslint-import-resolver-typescript": "^2.7.1",
    "eslint-plugin-import": "^2.26.0",
    "jasmine-core": "^5.1.2",
    "jasmine-marbles": "^0.9.2",
    "jasmine-spec-reporter": "^5.0.0",
    "karma": "^6.4.2",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^4.0.2",
    "karma-jasmine-html-reporter": "^1.7.0",
    "karma-spec-reporter": "^0.0.36",
    "karma-viewport": "^1.0.9",
    "mock-socket": "9.1.5",
    "protractor": "^7.0.0",
    "ts-node": "^8.3.0",
    "typescript": "^5.2.2"
  }
}
